import React from "react"
import Login from "../components/Login/"
import Seo from "../components/seo"

const LoginPage = () => {
  return (
    <>
      <Seo title="Login" />
      <Login />
    </>
  )
}

export default LoginPage
